angular.module(config.app.name).service('$_documents', function () {
    const RECTO = 'recto', VERSO = 'verso';
    return {
        prepareDocuments: function (documents) {

            let list = [];

            for (let i in documents) {
                let doc = documents[i];

                if (doc.type) { // document hasn't sub-types
                    doc.show = true;
                    list.push(doc);
                } else { // document has sub-types

                    if (doc.$or) {
                        let docObj = {
                            type: '',
                            field: '',
                            label: doc.label,
                            editable: true,
                            uploaded: false,
                            show: true,
                            hasChildren: true,
                            showMenu: false
                        };

                        if (i === 'document') {

                            docObj.type = docObj.field = i;

                            let docStatus = {
                                uploaded: [],
                                editable: [],
                                subDocs: []
                            };
                            for (let j = 0; j < doc.$or.length; j++) {
                                let subDoc = doc.$or[j];
                                subDoc = subDoc[Object.keys(subDoc)[0]];
                                docStatus.uploaded.push(subDoc.uploaded);
                                docStatus.editable.push(subDoc.editable);
                                let subDocObj = new Document({
                                    type: subDoc.type,
                                    field: subDoc.field,
                                    label: subDoc.label,
                                    editable: subDoc.editable,
                                    uploaded: subDoc.uploaded,
                                    show: true,
                                    isRectoVerso: false,
                                    isSubType: true,
                                    comment: subDoc.comment
                                });
                                docStatus.subDocs.push(subDocObj);
                            }
                            docObj.uploaded = docStatus.uploaded.every(v => v === true);
                            docObj.editable = docStatus.editable.some(v => v === true);
                            list.push(docObj);
                            for (let k = 0; k < docStatus.subDocs.length; k++) {
                                list.push(docStatus.subDocs[k]);
                            }
                            docStatus = null;

                        } else {

                            for (let j = 0; j < doc.$or.length; j++) {
                                let subDoc = doc.$or[j];

                                if (subDoc.$and) {

                                    subDoc = subDoc.$and;

                                    let recto, verso, side = subDoc[Object.keys(subDoc)[0]];
                                    if (side.field.indexOf(RECTO) > -1) {
                                        recto = side;
                                        verso = subDoc[Object.keys(subDoc)[1]];
                                    } else {
                                        verso = side;
                                        recto = subDoc[Object.keys(subDoc)[1]];
                                    }

                                    recto.isRecto = true;
                                    verso.isVerso = true;
                                    recto.show = recto.uploaded || verso.uploaded;
                                    verso.show = recto.show;
                                    recto.isSubType = verso.isSubType = true;

                                    let subDocObj = {
                                        type: recto.type,
                                        field: recto.field.replace('-' + RECTO, ''),
                                        label: recto.label.replace(RECTO, ''),
                                        editable: recto.editable || verso.editable,
                                        uploaded: recto.uploaded && verso.uploaded,
                                        show: false,
                                        isRectoVerso: true,
                                        isSubType: true
                                    };

                                    if (docObj.type === '') {
                                        docObj.type = docObj.field = recto.type;
                                        list.push(docObj);
                                    }

                                    list.push(subDocObj);
                                    list.push(recto);
                                    list.push(verso);

                                } else {

                                    subDoc = subDoc[Object.keys(subDoc)[0]];
                                    subDoc.isSubType = true;
                                    subDoc.show = subDoc.uploaded || typeof subDoc.comment === 'string';

                                    docObj.editable = docObj.editable && subDoc.editable;
                                    docObj.uploaded = subDoc.uploaded;

                                    if (docObj.type === '') {
                                        docObj.type = docObj.field = subDoc.type;
                                        list.push(docObj);
                                    }
                                    list.push(subDoc);
                                }
                            }
                            list[list.length - 1].editable = docObj.editable;
                        }

                    } else {

                        if (doc.$and) {

                            let o = {
                                type: '',
                                field: '',
                                label: doc.label,
                                editable: true,
                                uploaded: false,
                                show: true,
                                isRectoVerso: true,
                                showMenu: false
                            };
                            doc = doc.$and;
                            let subDoc1 = doc[Object.keys(doc)[0]];
                            let subDoc2 = doc[Object.keys(doc)[1]];
                            subDoc1.show = subDoc2.show = true;
                            subDoc1.isSubType = subDoc2.isSubType = true;

                            subDoc1.isRecto = true;
                            subDoc2.isVerso = true;

                            o.type = o.field = subDoc1.type;
                            o.editable = subDoc1.editable || subDoc2.editable;
                            o.uploaded = subDoc1.uploaded && subDoc2.uploaded;

                            list.push(o);
                            list.push(subDoc1);
                            list.push(subDoc2);

                        }
                    }
                }
            }
            this.updateParentDocuments(list);
            return list;
        },
        getAllDocumentsUploaded(documents) {
            let uploaded = true;
            for (let i = 0; i < documents.length; i++) {
                let document = documents[i];
                if (document.show &&
                        (document.isRectoVerso || document.hasChildren ||
                                typeof document.isSubType === 'undefined')) {
                    uploaded = uploaded && document.uploaded;
                }
            }
            return uploaded;
        },

        selectSubType(documents, subType) {
            if (!subType || !subType.editable) {
                return documents;
            }
            for (let i = 0; i < documents.length; i++) {
                let document = documents[i];
                if (document.isSubType && document.type === subType.type) {
                    document.show = document.field === subType.field;
                }
            }
            if (subType.isRectoVerso) {
                for (let i = 0; i < documents.length; i++) {
                    let document = documents[i];
                    if (document.isSubType && document.type === subType.type &&
                            (document.field === subType.field + '-' + RECTO ||
                                    document.field === subType.field + '-' + VERSO)) {
                        document.show = true;
                        document.parentType = subType.type;
                    }
                }
            } else {
                subType.show = true;
            }
            return documents;
        },
        getSubDocuments(documents, documentType) {
            let subDocuments = [];
            for (let i = 0; i < documents.length; i++) {
                let document = documents[i];
                if (document.type === documentType.field &&
                        document.isSubType && !document.isRecto && !document.isVerso) {
                    subDocuments.push(document);
                }
            }
            return subDocuments;
        },
        getRecto(documents, document) {
            for (let i = 0; i < documents.length; i++) {
                let doc = documents[i];
                if (doc.type === document.type &&
                        doc.field === document.field + '-' + RECTO) {
                    return doc;
                }
            }
            return null;
        },
        getVerso(documents, document) {
            for (let i = 0; i < documents.length; i++) {
                let doc = documents[i];
                if (doc.type === document.field &&
                        doc.field === document.field + '-' + VERSO) {
                    return doc;
                }
            }
            return null;
        },
        getDocument(documents, documentType = '', documentField = '') {
            if (documentType + '' + documentField + '' === '') {
                return null;
            }
            for (let i = 0; i < documents.length; i++) {
                let doc = documents[i];
                if (documentType !== '') {
                    if (doc.type === documentType) {
                        if (documentField !== '') {
                            if (doc.field === documentField) {
                                return doc;
                            }
                        } else {
                            return doc;
                        }
                    }
                }
            }
            return null;
        },
        getDocumentByType(documents, documentType = '', activeOnly = false) {
            if (documentType + '' === '') {
                return null;
            }
            let active = typeof activeOnly === 'boolean' && activeOnly === true ? true : false;
            for (let i = 0; i < documents.length; i++) {
                let doc = documents[i];
                if (documentType !== '') {
                    if (doc.type === documentType && doc.field !== documentType &&
                            (active ? doc.show === true : true)) {
                        return doc;
                    }
                }
            }
            return null;
        },
        getDocumentByField(documents, documentField = '', activeOnly = false) {
            if (documentField + '' === '') {
                return null;
            }
            let active = typeof activeOnly === 'boolean' && activeOnly === true ? true : false;
            for (let i = 0; i < documents.length; i++) {
                let doc = documents[i];
                if (documentField !== '') {
                    if (doc.field === documentField && (active ? doc.show === true : true)) {
                        return doc;
                    }
                }
            }
            return null;
        },
        getOtherSideByName(documents, document, documentType = null) {
            let side = document.substr(-5, 5);
            if ([RECTO, VERSO].indexOf(side) > -1) {
                let isRecto = side === RECTO, otherSide = isRecto ? VERSO : RECTO;
                document = document.replace(side, otherSide);
                for (let i = 0; i < documents.length; i++) {
                    let doc = documents[i];
                    if (doc.field === document) {
                        if (documentType !== null) {
                            if (doc.type === documentType) {
                                return doc;
                            } else {
                                continue;
                            }
                        }
                        return doc;
                    }
                }
                return null;
            }
            return null;
        },
        updateParentDocuments(documents) {
            for (let i = 0; i < documents.length; i++) {
                let document = documents[i];
                if (document.type === 'document') {
                    continue;
                }
                if (document.isRectoVerso) {
                    let recto = this.getRecto(documents, document), verso = this.getVerso(documents, document);
                    if (recto !== null && verso !== null) {
                        document.uploaded = recto.uploaded && verso.uploaded;
                        document.editable = recto.editable || verso.editable;
                    }
                } else if (document.hasChildren) {
                    let childDocument = this.getDocumentByType(documents, document.field, true);
                    if (childDocument !== null) {
                        if (childDocument.isRecto || childDocument.isVerso) {
                            let otherSideDoc = this.getOtherSideByName(documents, childDocument.field, childDocument.type);
                            if (childDocument !== null && otherSideDoc !== null) {
                                document.uploaded = childDocument.uploaded && otherSideDoc.uploaded;
                                document.editable = childDocument.editable || otherSideDoc.editable;
                            }
                        } else {
                            document.uploaded = childDocument.uploaded;
                            document.editable = childDocument.editable;
                        }
                    }
                }
            }
        }
    };
})